<template>
  <div>
    <div>
      <h2 class="text-xl text-purple-500 font-bold mb-2">
        <!-- <span class="text-3xl">⌛</span> За праиини -->
        {{ listTitle }}
      </h2>
      <div
        class="bg-gray-custom rounded-2xl p-5 transition-all duration-500 ease-in-out box-h"
        @drop="onDrop($event, listStatus)"
        @dragenter.prevent
        @dragover.prevent
      >
        <draggable v-model="tasks" v-bind="dragOptions" item-key="id" tag="ul">
          <template v-slot:item="{ element }">
            <div
              class="relative bg-white rounded-2xl border pt-4 pb-3 px-4 mb-3"
              :draggable="true"
              @dragstart="startDrag($event, element)"
              @click="
                showUpdateModal = true;
                updateId = element.id;
              "
            >
              <h3 class="text-lg mb-1" v-text="element.title" />
              <p class="text-sm" v-text="element.description" />
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <button
      class="fixed bottom-5 right-5 bg-purple-500 text-white text-6xl w-20 h-20 rounded-full font-thin animate-bounce"
      @click="showModal = true"
    >
      <span class="relative -top-1">+</span>
    </button>
    <!-- Modal for adding task -->
    <div
      class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center"
      v-if="showModal"
    >
      <div class="bg-white rounded-2xl p-5 w-1/2">
        <div class="flex justify-between">
          <h2 class="text-2xl font-bold mb-4">Охо нова задачка??</h2>
          <button
            class="bg-black w-8 h-8 rounded-full text-white text-xl font-thin"
            @click="showModal = false"
          >
            X
          </button>
        </div>
        <form @submit.prevent="addTodo">
          <div class="mb-4">
            <label class="block text-sm font-bold mb-2" for="title"
              >Имиту на таска</label
            >
            <input
              class="border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="title"
              type="text"
              placeholder="Title"
              v-model="title"
            />
          </div>
          <div class="mb-4">
            <label class="block text-sm font-bold mb-2" for="description"
              >Кай нещо тука да не забраиш после...</label
            >
            <textarea
              class="border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="description"
              placeholder="Description"
              v-model="description"
            ></textarea>
          </div>
          <div class="flex justify-center">
            <button
              class="bg-purple-500 w-40 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              Дайй мууу
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Modal to update -->
    <div
      class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-20"
      v-if="showUpdateModal"
    >
      <div class="bg-white rounded-2xl p-5 w-1/2">
        <div class="flex justify-between">
          <h2 class="text-2xl font-bold mb-4">Ш променяш таска ли уе?</h2>
          <button
            class="bg-black w-8 h-8 rounded-full text-white text-xl font-thin"
            @click="showUpdateModal = false"
          >
            X
          </button>
        </div>
        <form @submit.prevent="updateTodo(null)">
          <div class="mb-4">
            <label class="block text-sm font-bold mb-2" for="title"
              >Кък ша са каа?</label
            >
            <input
              class="border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="title"
              type="text"
              placeholder="Title"
              v-model="title"
            />
          </div>

          <div class="mb-4">
            <label class="block text-sm font-bold mb-2" for="description"
              >Тука ш променяш ли?</label
            >
            <textarea
              class="border appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="description"
              placeholder="Description"
              v-model="description"
            ></textarea>
          </div>
          <div class="flex justify-center">
            <button
              class="bg-purple-500 w-40 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full"
              type="submit"
            >
              Ъпдейтни
            </button>
            <button
              class="bg-red-500 w-40 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full ml-4"
              type="button"
              @click="deleteTodo"
            >
              Боклук
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
export default {
  name: "List",
  props: {
    allTodos: {
      type: Array,
      default: () => [],
    },
    todos: {
      type: Array,
      default: () => [],
    },
    listTitle: {
      type: String,
      default: "",
    },
    listStatus: {
      type: String,
      default: "",
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      dragOptions: {
        animation: 150,
        ghostClass: "ghost",
        chosenClass: "chosen",
        dragClass: "drag",
      },
      showModal: false,
      showUpdateModal: false,
      updateId: null,
      confettiEnabled: false,
      title: "",
      description: "",
      status: "",
      timeForDelete: 900,
    };
  },
  watch: {
    updateId(id) {
      if (id) {
        this.showUpdateModal = true;
        const todo = this.todos.find((todo) => todo.id === id);
        this.title = todo.title;
        this.description = todo.description;
        this.status = todo.status;
      }
    },
    showUpdateModal(value) {
      if (!value) {
        this.updateId = null;
        this.title = "";
        this.description = "";
        this.status = "";
      }
    },
    showModal(value) {
      if (!value) {
        this.title = "";
        this.description = "";
        this.status = "";
      }
    },
  },
  computed: {
    tasks() {
      return this.todos;
    },
  },
  methods: {
    startDrag(event, item) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", item.id);
      console.log("start drag", item);
    },
    onDrop(event, status) {
      event.preventDefault();
      const itemID = event.dataTransfer.getData("itemID");
      const item = this.allTodos.find((todo) => todo.id === Number(itemID));
      item.status = status;
      this.updateTodo(status, itemID);
    },
    async addTodo() {
      const data = {
        title: this.title,
        description: this.description,
        status: "pending",
      };
      await axios.post("https://todo-api.g-home.site/api/todos", data);
      this.$emit("getTodos");
      this.showModal = false;
      this.title = "";
      this.description = "";
    },
    async updateTodo(status, itemID) {
      const data = this.title
        ? {
            title: this.title,
            description: this.description,
            status: status || this.status,
          }
        : {
            status: status || this.status,
          };
      await axios.patch(
        `https://todo-api.g-home.site/api/todos/${this.updateId || itemID}`,
        data
      );
      this.$emit("getTodos");
      this.showUpdateModal = false;
    },
    async deleteTodo() {
      await axios.delete(
        `https://todo-api.g-home.site/api/todos/${this.updateId}`
      );
      this.$emit("getTodos");
      this.showUpdateModal = false;
    },
  },
};
</script>
