<template>
  <div
    class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-x-12 mt-6 px-6 md:px-2 gap-y-8 md:gap-y-0"
  >
    <List
      :allTodos="todos"
      :todos="pendings"
      :listStatus="'pending'"
      :listTitle="'⌛ За праиини'"
      @getTodos="getTodos"
    />
    <List
      :allTodos="todos"
      :todos="progress"
      :listStatus="'in_progress'"
      :listTitle="'🚀 Баш ся'"
      @getTodos="getTodos"
    />
    <List
      :allTodos="todos"
      :todos="done"
      :listStatus="'done'"
      :listTitle="'🎯 Туто финито'"
      @getTodos="getTodos"
    />
    <p
      class="text-purple-500 text-9xl fixed bottom-20 font-bold italic opacity-10 transform skew-y-6 pointer-events-none left-40 hidden md:block"
    >
      #MegaSimple
    </p>
  </div>
</template>

<script>
import axios from "axios";
import List from "./components/List.vue";

export default {
  components: {
    List,
  },
  data() {
    return {
      todos: [],
      pendings: [],
      progress: [],
      done: [],
      timeToUpdate: 180000,
    };
  },
  async mounted() {
    await this.getTodos();
    // set interval of 3 minutes
    setInterval(() => {
      this.getTodos();
    }, this.timeToUpdate);
    // setTimeout(() => {
    //   this.done.map(async (todo) => {
    //     const id = todo.id;
    //     await axios.delete(`https://todo-api.g-home.site/api/todos/${id}`);
    //     this.getTodos();
    //   });
    // }, this.timeForDelete);
  },
  watch: {
    todos: {
      handler() {
        this.pendings = this.todos.filter((todo) => todo.status === "pending");
        this.progress = this.todos.filter(
          (todo) => todo.status === "in_progress"
        );
        this.done = this.todos.filter((todo) => todo.status === "done");
      },
      deep: true,
    },
    updateId(id) {
      if (id) {
        this.showUpdateModal = true;
        const todo = this.todos.find((todo) => todo.id === id);
        this.title = todo.title;
        this.description = todo.description;
        this.status = todo.status;
      }
    },
    showUpdateModal(value) {
      if (!value) {
        this.updateId = null;
        this.title = "";
        this.description = "";
        this.status = "";
      }
    },
    showModal(value) {
      if (!value) {
        this.title = "";
        this.description = "";
        this.status = "";
      }
    },
  },
  methods: {
    async getTodos() {
      const response = await axios.get(
        "https://todo-api.g-home.site/api/todos"
      );
      this.todos = response.data;
      this.pendings = this.todos.filter((todo) => todo.status === "pending");
      this.progress = this.todos.filter(
        (todo) => todo.status === "in_progress"
      );
      this.done = this.todos.filter((todo) => todo.status === "done");
    },
  },
};
</script>

<style>
.box-h {
  position: relative;
  min-height: 80vh;
  max-height: 90vh;
  overflow: scroll;
}
</style>
